import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'virtual_events',
  formId: 'UK_CATEGORY_REMOTEEXPERIENCE_WIDE',
  title: 'Virtual Events',
  subTitle: 'A little virtual party never hurt anyone!',
  icon: '/icons/events.svg',
  image: '/assets/images/services/virtual_events/virtual_events_illustration.svg',
  theme: 'violet',
  sections: [
    {
      sectionId: 'hero',
      label: 'Events',
      title: 'A little virtual party never hurt anyone!',
      description:
        'Live events may not be back just yet but that doesn’t mean all fun is on hold. Our talented suppliers have spent the last 12 months inventing virtual entertainment none of us would have believed in 2019!',
      image: '/assets/images/services/virtual_events/virtual_events_illustration.svg',
      icon: '/icons/events.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'Bringing your team together is more important than ever',
      description:
        'We’ve done it all... from the monthly Friday bar to the big annual Christmas party. Save time and let us help you with all the nitty gritty work so you can join the party and not just facilitate it.',
      optionsItems: [
        {
          icon: '/assets/images/services/virtual_events/service_1.svg',
          title: 'Team bonding',
          description: 'You design it. Small to BIG. From beers to cocktails, food to DJs. Everything is possible.',
        },
        {
          icon: '/assets/images/services/virtual_events/service_2.svg',
          title: 'Interactive workshops',
          description: 'Work anniversary, birthdays, baby showers! You name it, we’ve done it. It’s appreciation time!',
        },
        {
          icon: '/assets/images/services/virtual_events/service_3.svg',
          title: 'Talks & entertainment',
          description:
            'Summer party, Christmas party, Halloween party? All those special things you need for a seasonal party!',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Get the party started in just a few steps',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/virtual_events/journey_1.svg',
          title: 'Send us your request',
          description: 'Tell us what would make the event magical.',
        },
        {
          icon: '/assets/images/services/virtual_events/journey_2.svg',
          title: 'Receive a proposal',
          description: 'Together we’ll create the perfect plan for your upcoming event.',
        },
        {
          icon: '/assets/images/services/virtual_events/journey_3.svg',
          title: 'Accept!',
          description: 'We’ll send a detailed proposal with items and prices.',
        },
        {
          icon: '/assets/images/services/virtual_events/journey_4.svg',
          title: 'Switch on the fun!',
          description: 'Party on! Lean back and enjoy the celebration.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies choose Good Monday to manage their virtual activities',
      description: '',
      image: '/assets/images/services/virtual_events/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-award-outline',
          title: 'Find the best of the best',
          description:
            'We’ve handpicked and tested the best virtual providers for your peace of mind.',
        },
        {
          icon: 'eva eva-bulb-outline',
          title: 'Activities you’ve never even heard of',
          description: 'We’re bored of quizzes too. Let us show you what else is out there!',
        },
        {
          icon: 'eva eva-gift-outline',
          title: 'Make it unique',
          description:
            'We don’t just send a link. We can send goodie boxes to your guests to enjoy during your virtual party.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that with Good Monday you can arrange a box of snacks to arrive at the same time as your virtual party?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
